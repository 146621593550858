canvas {
  width: auto;
  height: 100dvh;
  position: fixed;
  aspect-ratio: 9/15.5;
  left: 50%;
  transform: translateX(-50%);
}

body {
  text-align: center;
  background-color: #356eff;
  width: 100vw;
  height: 100dvh;
}

.main {
  overflow: hidden;
}

h1 {
  color: white;
  margin-bottom: 5rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: lightgray;
}